<template>
  <div>
    <v-container class="d-flex flex-column">
      <a href="https://shop.cosmicind.com/blogs/recettes-de-belettes/" style="text-decoration:none;">
        <Train/>
      <div class="d-flex text-center mt-12 mx-auto flex-column">
      <h2 style="color:white; text-decoration:none;">New recipes on the way from Recettes de Belettes!</h2>
      </div>
      </a>
      <a href="https://shop.cosmicind.com/" style="text-decoration:none;">
     <v-img src="../assets/colorblast_hero.png"  contain max-height="" height="100%" max-width="975px" width="100%" class="d-flex mx-auto mt-8 hero-border" elevation="0"></v-img>
     <div class="d-flex text-center mt-8 mx-auto flex-column">
       <h2 style="color:white">Check out the all-new ColorBlast line at the shop!</h2>
     </div>
      </a>
    </v-container>
  </div>
</template>

<script>
import Train from '../components/Train.vue';
export default {
  name: 'Home',
  title: 'Cosmic Industries - Home',
  components: { Train },
  computed: {
    featuredProducts: function () {
      let prodList = this.$getParent('App').products.filter(x => x.fields["Featured"] === true);
      return prodList
    }
  }
};
</script>

<style>
.main {
  color: #3949AB;
  background: #3949AB;
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 800;
}
.featured {
  max-width: 62rem;
}

/* //hero// */
.hero-border {  
  border-radius: 5px;
  line-height:1.5em;
  border:solid 1px transparent;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.line-height {
  line-height: 1.2em;
}
</style>