<template>
  <v-container>

   <div class="container background-white"> 
     

     <div class="mask">
     
     <div class="sky"></div>
     
     <div class="mountains">
     </div>

     <div class="clouds">
     </div>
     <div class="treesFar"></div>
     <div class="treesMid"></div>

     <div class="stamp-outside"><div class="stamp-inside"></div></div>
          <!-- <div class="stamp-cosmic"></div> -->
     <div class="onTrain">
     <div class="train-inside">
       <img src="../assets/train2-inside.png">
     </div>
     <div class="Abba">
       <img src="../assets/train2.gif">
     </div>
      <div class="treesFront1"></div>
     <div class="train-outside">
       <img src="../assets/train2-outside.png">
     </div>
     </div>
     </div>
   </div>
  </v-container>
</template>

<script>
 export default {
  name: "Train",
  data: function() {
	return {
		publicPath: process.env.BASE_URL
	}
  }
};
</script>
<style scoped>
.container {
  position: relative;

  height: 650px;
  max-width: 1000px;
  width: 100%;
  margin-top: .5em;
  margin-bottom: 1em;
  
}

 @media screen and (max-width: 500px) {
.container {
  position: relative;

  height: 650px;
  max-width: 1000px;
  width: 100%;
  margin-top: .2em;
  margin-bottom: 1em;
  
}
  
}
.background-white {
  background:white;
  padding: 0;
   border-radius: 7px;
   box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mask {
  position: absolute;
  overflow: hidden;
  
  height: 650px;
  width: 100%;
  border-radius: 7px;
 border: 15px solid transparent;
  border-image: 16 repeating-linear-gradient(45deg, #cf3434 0, #cf3434 1em, transparent 0, transparent 2em,navy 0, navy 3em, transparent 0, transparent 4em);
}
/* .stamp-cosmic {
  position: absolute;
  width: 10em;
  height: 100%;
  background: url(../assets/stampstamp.png);
  background-size: contain;
	background-repeat: no-repeat;
  left: 6em;
  top: 1em;
  transform: rotate(-15deg);
} */
.stamp-outside {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-size: contain;
	background-repeat: no-repeat;
  height: 6.5em;
  width: 6em;
  /* z-index: 1000; */
  align-content: center;
  align-self: center;
  justify-content: center;

  border: white dashed 4px;

  right: 1.5em;
  top: 1.5em;

  transform: rotate(-10deg);

  
}
.stamp-inside {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-self: center;
	background: url(../assets/stamp.png);
  background-size: contain;
	background-repeat: no-repeat;
  background-color: white;
  height: 6em;
  width: 100%;
  /* z-index: 1000; */
  justify-content: center;
  align-self: center;
  border: white solid 5px;
  
}

.sky {
  position: absolute;
	background: url(../assets/bgbacksky.png);
	background-position: 0px -100px;
  background-size: cover;
	background-repeat: repeat-x;
	/* animation: animBg 90s linear infinite; */
	white-space: nowrap;
	margin: 0 auto;
	height: 600px;
	width: 100%
  
  
}
.clouds {
  position: absolute;
  background: url(../assets/clouds.png);
	background-position: 0px 0px;
	background-repeat: repeat-x;

	height: 300px;
	width: 100%;
  animation: animBgClouds 90s linear infinite;
}

.mountains {
  position: absolute;
  top: 190px;

	background: url(../assets/bgback.png);

	background-repeat: repeat-x;

	height: 400px;
	width: 100%;
  animation: animBgMtn 200s linear infinite;
}
.treesFar {
  position: absolute;
  top: 370px;
	background: url(../assets/treelayermid2.png);
	background-position: 0px 0px;

	background-repeat: repeat-x;
	animation: animBg2 60s linear infinite;

	height: 200px;
	width: 100%
}
.treesMid {
   position: absolute;
  top: 330px;
	background: url(../assets/treelayermid1.png);
	background-position: 0px 0px;

	background-repeat: repeat-x repeat-y;
	animation: animBg1 15s linear infinite;
	white-space: nowrap;

	height: 300px;
	width: 100%;
}
.train-outside img {
   position: absolute;
	margin: 0 auto;
  top: 8rem;
	height: auto;
	width: 59.5rem;
}
.train-inside img {
  position: absolute;
  top: 8rem;
	margin: 0 auto;
	height: auto;
	width: 59.5rem;
}
.Abba img {
   position: relative;
  margin-top: 25em;
  margin-left:13em;
	max-height: 200px;
  height: 100%; 
	width: auto;
}
.onTrain {
position: relative;

animation: animTrain 1s linear infinite;

transform: translateX(0%);

   
}



 @media screen and (max-width: 600px) {

   .onTrain {
position: relative;
animation: animTrain 1s linear infinite;
transform: translateX(-25%); 
  }
  
}
 @media screen and (max-width: 500px) {

   .onTrain {
position: relative;
animation: animTrain 1s linear infinite;
transform: translateX(-35%); 
  }
}
 @media screen and (max-width: 450px) {

   .onTrain {
position: relative;
animation: animTrain 1s linear infinite;
transform: translateX(-60%); 
  }
}

 @media screen and (max-width: 400px) {

   .onTrain {
position: relative;
animation: animTrain 1s linear infinite;
transform: translateX(-65%); 
  }
}



.treesFront1 {
  position: absolute;
  top: 250px;
	background: url(../assets/treelayermid1.png);
	background-repeat: repeat-x;
	animation: animBg1 10s linear infinite;
	white-space: nowrap;
	height: 400px;
	width: 100%;
  opacity: 0.1;
}


 @media screen and (max-width: 600px) {

   .treesFront1 {

transform: translateX(25%); 
  }
  
}
 @media screen and (max-width: 500px) {

  .treesFront1 {

transform: translateX(35%); 
  }
}
 @media screen and (max-width: 450px) {

  .treesFront1 {

transform: translateX(60%); 
  }
}
 @media screen and (max-width: 400px) {

  .treesFront1 {

transform: translateX(65%); 
  }
}


@keyframes animBgClouds {
  0%{
	background-position: 0px;
  }
  100%{
  background-position: -1800px;
  }
}
@keyframes animBgMtn {
  0%{
	background-position: 0px;
  }
  100%{
  background-position: -1500px;
  }
}
@keyframes animBg1 {
  0%{
  background-position: 0px;
  }
  100%{
    background-position: -3234px;
  }
}
@keyframes animBg2 {
  0%{
  background-position: 0px;
  }
  100%{
    background-position: -1892px;
  }
}
@keyframes animTrain {
  0%{
	margin-top: 0;
  }
  50%{
  margin-top: 10px;
  }
  100%{
	margin-top: 0;
  }
}

</style>