import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
            primary: '#8bc34a',
            secondary: '#00bcd4',
            accent: '#009688',
            error: '#f44336',
            warning: '#ff5722',
            info: '#03a9f4',
            success: '#4caf50'
        },
      },
    },
    icons: {
      iconfont: 'mdiSvg',
    }
  })

export default vuetify
