<template>
  <v-app id="app">
    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      color="indigo"
      dark
    >
      <template v-slot:prepend>
        <v-img src="./assets/roundlogo.png" class="my-3" height="100px" contain></v-img>
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <a href="https://shop.cosmicind.com/" style="text-decoration:none;"><v-list-item>
          <v-list-item-content>
              <v-list-item-title>Store</v-list-item-title>
          </v-list-item-content>
        </v-list-item></a>
        <v-list-item
          to="/characters"
        >
          <v-list-item-content>
              <v-list-item-title>Characters</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          to="/about"
        >
          <v-list-item-content>
              <v-list-item-title>About</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <a href="https://shop.cosmicind.com/blogs/recettes-de-belettes/" style="text-decoration:none;">
        <v-list-item>
          <v-list-item-content>
              <v-list-item-title>Recettes de Belettes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </a>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      color='lime'
      app
      height="100px" 
    >
      <v-btn 
        icon 
        large
        color="black"
        @click.stop="drawer = !drawer"
        v-if="$vuetify.breakpoint.smAndDown"
      >
        <v-icon>mdi-dots-grid</v-icon>
      </v-btn>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-spacer></v-spacer>
        <a href="https://shop.cosmicind.com/" style="text-decoration:none;"><v-btn text large class="headings">Store</v-btn></a>
        <v-btn text large to="/characters" class="headings">Characters</v-btn>
      </template>
      <v-spacer></v-spacer>
      <router-link to="/">
        <v-img
          class="my-1"
          max-width="150px"
          src="./assets/logov2.png"
          contain
        >
        </v-img>
      </router-link>
      <v-spacer></v-spacer>
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn text large to="/about" class="headings">About</v-btn>
        <a href="https://shop.cosmicind.com/blogs/recettes-de-belettes/" style="text-decoration:none;"><v-btn text large class="headings">Recettes de Belettes</v-btn></a>
        <v-spacer></v-spacer>
        <span hidden id="snipcartCount" class="snipcart-items-count">0</span>
        <span hidden class="snipcart-total-price">0.00</span>
      </template>
    </v-app-bar>

    <v-main class="main">
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer padless color="indigo-lighten-5">
      <v-col
        class="text-center"
        cols="12"
      >
        {{ new Date().getFullYear() }} — <strong>Cosmic Industries</strong> — <a href="https://www.instagram.com/thewoodcharles/"> #cosmic</a>
      </v-col>
    </v-footer>

  </v-app>
</template>

<script>
  import ProductData from '@/json/products.json';
  export default {
    name: 'App',
    data () {
      return {
        drawer: null,
        items: [
          { title: 'Store', icon: 'mdi-dashboard', location: 'store.cosmicind.com' },
          { title: 'About', icon: 'mdi-account_box', location: '/about' },
          { title: 'Recettes de Belettes', icon: 'mdi-account_box', location: 'https://shop.cosmicind.com/blogs/recettes-de-belettes' },
          { title: 'Characters', icon: 'mdi-account_box', location: '/characters' },
        ],
        products: ProductData.records
      }
    },
    computed: {
      /* global Snipcart:false */
      Snipcart: () => Snipcart
    }
  }
</script>

<style lang="scss">
.headings {
  text-transform: none;
  font-weight: 800;
}
</style>
